<template>
  <div>
    <Modal
      :open="modalOpen"
      @close="$emit('close')"
    >
      <div class="border-b-2 bg-white">
        <div class="flex items-center px-6 pb-6">
          <Icon
            width="42px"
            height="42px"
            view-box="0 0 80 80"
            class="mr-2"
          >
            <Celebration />
          </Icon>
          <h3 class="font-medium text-lg">
            Example test ended
          </h3>
        </div>
      </div>

      <div class="p-6">
        <template v-if="hasSolutions">
          <p class="mb-6">
            You can now view the solutions
          </p>
          <BaseButton @click="$emit('showAnswers')">
            View correct answers
          </BaseButton>
        </template>
        <BaseButton
          v-else
          :href="`/tests/${$route.params.assessment}/`"
        >
          Back to tests
        </BaseButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import Icon from '@components/Icons/Icon'
import Celebration from '@components/Icons/Celebration'

export default {
  components: {
    Modal,
    Icon,
    Celebration
  },

  props: {
    instructionSlug: {
      type: String,
      default: ''
    },
    modalOpen: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasSolutions() {
      return this.instructionSlug === 'choice' || this.instructionSlug === 'likely'
    }
  }
}
</script>
