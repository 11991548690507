<template>
  <OnClickOutside :do="close">
    <ais-instant-search
      :index-name="indexName"
      :search-client="searchClient"
      class="relative"
    >
      <div
        v-show="selected"
        class="form-input rounded-lg truncate whitespace-nowrap mt-1"
        @click="resetSelection()"
      >
        {{ selected }}
      </div>

      <SearchInput
        v-show="!selected"
        :ref="`dropdown-${indexName}`"
        class="mt-1"
        :v="v"
        @update="customEntry = $event"
        @keydown.esc="close"
        @keydown.up="highlightPrev"
        @keydown.down="highlightNext"
        @keydown.enter.prevent="selectHighlighted"
        @keydown.tab="close"
      />

      <ais-state-results>
        <template slot-scope="{ state: { query } }">
          <ais-hits
            v-show="(query && query.length > 0) && (!selected && searchedItems.length)"
            :transform-items="transformItems"
          >
            <ul
              ref="options"
              slot-scope="{ items }"
              class="text-sm ml-auto absolute inset-0 bg-white shadow-lg max-h-60 h-60 rounded-lg w-full z-40 overflow-auto border border-gray-300"
              style="top: 3rem;"
            >
              <li
                v-for="(item, index) in items"
                :key="item.objectID"
                tabindex="-1"
                :class="{ 'bg-gray-200': index === highlightedIndex }"
                class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4"
                @click="select(item.name)"
              >
                <span class="font-normal block truncate">
                  {{ item.name }}
                </span>
              </li>
            </ul>
          </ais-hits>
        </template>
      </ais-state-results>
    </ais-instant-search>
  </OnClickOutside>
</template>

<script>
import SearchInput from '@components/Algolia/SearchInput'
import OnClickOutside from '@components/OnClickOutside'

export default {
  components: {
    SearchInput,
    OnClickOutside
  },

  props: {
    indexName: {
      type: String,
      default: null
    },
    searchClient: {
      type: Object,
      default: null
    },
    v: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      selected: this.v.$model ? this.v.$model : '',
      searchedItems: [],
      highlightedIndex: 0,
      clear: false,
      customEntry: ''
    }
  },

  watch: {
    selected(val) {
      if (val) {
        this.$emit('selected', this.selected)
        this.customEntry = ''
      }
    },

    customEntry(val) {
      if (val && !this.selected) {
        this.$emit('selected', this.customEntry)
      }
    }
  },

  methods: {
    resetSelection() {
      this.selected = ''
      this.$nextTick(() => this.$refs[`dropdown-${this.indexName}`].focus())
    },

    transformItems(items) {
      this.searchedItems = items
      return items
    },

    select(option) {
      this.selected = option
      this.highlightedIndex = 0
    },

    selectHighlighted() {
      let resultName = this.searchedItems.map(item => item.name)
      this.select(resultName[this.highlightedIndex])
    },

    scrollToHighlighted() {
      if (this.$refs.options.children[this.highlightedIndex]) {
        this.$refs.options.children[this.highlightedIndex].scrollIntoView({
          block: 'nearest'
        })
      }
    },

    highlight(index) {
      this.highlightedIndex = index

      if (this.highlightedIndex < 0) {
        this.highlightedIndex = this.searchedItems.length - 1
      }

      if (this.highlightedIndex > this.searchedItems.length - 1) {
        this.highlightedIndex = 0
      }

      this.scrollToHighlighted()
    },

    highlightPrev() {
      this.highlight(this.highlightedIndex - 1)
    },

    highlightNext() {
      this.highlight(this.highlightedIndex + 1)
    },

    close() {
      if (!this.searchedItems.length || this.selected) {
        return
      }
      this.selected = ''
      this.searchedItems = []
    }
  }
}
</script>
