<template>
  <div
    class="relative m-auto"
  >
    <canvas
      :id="id"
      :width="width"
      :height="height"
    >
    </canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import { themeColors } from '@utils/themeColors'

export default {
  props: {
    graphData: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      themeColors: themeColors(),
      chart: {},
      width: '100%',
      height: '100%',
      id: this.graphData.canvasId
    }
  },

  computed: {
    /**
     * @return {Object}
     */
    globalGraphOptions() {
      return {
        'tooltips': {
          'callbacks': {
            'title': function(tooltipItems, data) {
              return tooltipItems.map(tooltipItem => {
                return data.labels[tooltipItem.index]
              })
            }
            // 'label': function(tooltipItem, data) {
            //   var dataset = data.datasets[tooltipItem.datasetIndex]
            //   // var total = dataset.data.reduce(function(prev, curr, i, array) {
            //   //   return prev + curr
            //   // })
            //   var currentValue = dataset.data[tooltipItem.index]
            //   // var percentage = Math.floor((currentValue / total) * 100)
            //   return currentValue + '%'
            // }
          }
        }
      }
    },

    graphConfig() {
      return JSON.parse(this.graphData.config)
    }
  },

  watch: {
    id: {
      async handler(newOption, oldOption) {
        await this.chart.destroy()
        await this.drawChart()
      }
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize() {
      // Calculate new canvas size based on window

      if (window.innerWidth > 1000) {
        this.width = null
        this.height = null
      } else if (window.innerWidth < 1000) {
        this.height = '50vh'
        this.width = '50vw'
      }

      this.$nextTick(() => {
        if (this.graphData) {
          this.drawChart()
        }
      })
    },

    drawChart(colrtime) {
      const ctx = document.getElementById(this.graphData.canvasId)
      // eslint-disable-next-line

      Chart.defaults.global.defaultFontColor = '#2d3748'
      Chart.defaults.global.defaultFontFamily = "'Circular', Helvetica, sans-serif"
      Chart.defaults.global.defaultFontSize = 16

      const colors = [
        this.themeColors['graph-primary'],
        '#faabab',
        this.themeColors['tertiary'],
        '#e53e3e',
        this.themeColors['secondary-400'],
        '#e2e8f0',
        '#F56565',
        '#D69E2E',
        '#D53F8C',
        '#38B2AC',
        '#2B6CB0',
        '#FBB6CE'
      ]
      let color = 0

      // Sets custom colours for graphs
      this.graphConfig.data.datasets = this.graphConfig.data.datasets.map(dataset => {
        if (Array.isArray(dataset.backgroundColor)) {
          dataset.backgroundColor = colors
        } else {
          dataset.backgroundColor = colors[color]
          dataset.borderColor = colors[color]

          color++

          if (color > colors.length) {
            color = 0
          }
        }
        return dataset
      })

      this.chart = new Chart(ctx, {
        type: this.graphConfig.type,
        data: this.graphConfig.data,
        options: {
          ...this.graphConfig.options,
          ...this.globalGraphOptions
        }
      })
    }
  }
}
</script>
