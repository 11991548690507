<template>
  <div class="h-screen bg-gray-100 sm:pt-16">
    <div class="max-w-6xl px-4 mx-auto bg-white sm:px-12">
      <header class="flex items-center justify-between py-8">
        <div>
          <h1 class="text-3xl font-semibold">
            At this stage of my career, I am most motivated by:
          </h1>
          <p class="text-gray-600">
            Distribute the points across your work drives accordingly.
          </p>
        </div>
        <span class="w-32 text-5xl text-right text-secondary">
          {{ allocatedPoints }}<span class="text-base text-gray-600"> /{{ total }}</span>
        </span>
      </header>

      <ul class="mt-4 divide-y divide-gray-400">
        <li
          v-for="(drive, index) in drives"
          :key="index"
          class="flex flex-wrap items-center justify-between py-4"
        >
          <div>
            <p :class="['text-lg font-medium capitalize duration-300', highlightDrive(drive, index)]">
              {{ drive.name }}
            </p>
            <p class="text-sm text-gray-700">
              {{ drive.description }}
            </p>
          </div>

          <div class="text-lg">
            <button
              class="px-4 py-1 mr-2 leading-none text-white rounded focus:outline-none bg-secondary hover:bg-secondary-400"
              @click="decrement(drive, index)"
            >
              −
            </button>
            <span class="inline-block w-10 text-center text-gray-600">
              {{ drive.value }}/{{ driveTotal }}
            </span>
            <button
              class="px-4 py-1 ml-2 leading-none text-white rounded focus:outline-none bg-secondary hover:bg-secondary-400"
              @click="increment(drive, index)"
            >
              +
            </button>
          </div>
        </li>
      </ul>
      <AssessmentEndButton
        button-type="button"
        button-text="Submit"
        :loading="submitInProgress || endTestEmitted"
        :disabled="!canSubmitDrives"
        class="py-8 text-center"
        @endTest="endTest()"
      />
      <Modal
        :open="modalOpen"
        @close="$emit('close')"
      >
        <div class="bg-white border-b-2">
          <div class="flex items-center px-6 pb-6">
            <Icon
              width="42px"
              height="42px"
              view-box="0 0 80 80"
              class="mr-2"
            >
              <Celebration />
            </Icon>
            <h3 class="text-lg font-medium">
              Example test ended
            </h3>
          </div>
        </div>

        <div class="p-6">
          <BaseButton
            href="/tests/drives/"
          >
            Back to tests
          </BaseButton>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import AssessmentEndButton from '@components/Assessments/AssessmentEndButton'
import Celebration from '@components/Icons/Celebration'

import Modal from '@components/Modal'
import Icon from '@components/Icons/Icon'

import { drives } from '@data/drivesTest.js'

export default {
  components: {
    Modal,
    Icon,
    AssessmentEndButton,
    Celebration
  },

  props: {
    isExample: {
      type: Boolean,
      default: false
    },
    submitInProgress: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      drives,
      total: 20,
      driveTotal: 5,
      allocatedPoints: 0,
      modalOpen: false,
      endTestEmitted: false
    }
  },

  computed: {
    canSubmitDrives() {
      return this.allocatedPoints >= 20
    }
  },

  methods: {
    increment(drive, index) {
      if (this.allocatedPoints >= 20 || this.drives[index].value >= 5) {
        return
      }
      this.allocatedPoints++
      this.drives[index].value++
    },

    decrement(drive, index) {
      if (this.allocatedPoints <= 0 || this.drives[index].value <= 0) {
        return
      }
      this.allocatedPoints--
      this.drives[index].value--
    },

    highlightDrive(drive, index) {
      return this.drives[index].value > 0 ? 'text-secondary' : null
    },

    endTest() {
      if (this.isExample) {
        this.modalOpen = true
        return
      }

      if (this.endTestEmitted) {
        console.error('End test already sent')
      }
      this.endTestEmitted = true

      const driveTotalsAsObject = this.drives.reduce(
        (obj, drive) => Object.assign(obj, { [drive.name]: drive.value }), {})

      this.$store.commit('drives/setResults', driveTotalsAsObject)

      this.$emit('endTest')
    }
  }
}
</script>
