<template>
  <div>
    <div
      v-show="exam"
      class="relative w-full max-w-3xl px-4 py-12 mx-auto"
    >
      <header
        v-if="exam"
        class="pb-2 text-center"
      >
        <h1 class="text-3xl font-medium leading-none text-center">
          {{ exam.name }}
        </h1>
        <h3 class="mt-6 mb-10 leading-none">
          <span v-if="exam.questionsCount">{{ exam.questionsCount }} questions</span>
          <span v-if="exam.questionsCount && exam.minutes && !timerDisabled"> | </span>
          <span v-if="exam.minutes && !timerDisabled">{{ exam.minutes }} minutes</span>
        </h3>
      </header>

      <ul
        class="mb-4 text-sm list-disc"
      >
        <li
          class="ml-4 disc"
        >
          Please allow the use of your webcam/camera (snapshots will be taken at regular intervals during the test) and do not leave full-screen mode. These measures are in place to ensure equality for all. If you decide not to enable your camera, you can still proceed with the test.
        </li>
      </ul>

      <ul
        v-if="exam.rules"
        class="text-sm list-disc"
      >
        <li
          v-for="(rule, index) in exam.rules"
          :key="index"
          class="mt-4 ml-4 disc first:mt-0"
        >
          {{ rule }}
        </li>
      </ul>

      <div
        v-if="extraTimeAllowed && !timerDisabled"
        class="mt-4 text-center"
      >
        <button
          v-if="!showExtraTimeOption"
          class="text-sm underline"
          @click="showExtraTimeOption = true"
        >
          Apply for extra time
        </button>
        <label
          v-else-if="showExtraTimeOption"
          class="text-sm"
        >
          <input
            v-model="hasExtraTime"
            type="checkbox"
          >
          I have been given permission to be provided with extra time
        </label>
      </div>

      <div
        v-if="!canSeeStart"
        class="max-w-lg px-4 py-2 mx-auto my-6 border rounded"
      >
        <h3 class="flex items-center my-2 space-x-2 text-lg font-bold">
          <Icon
            view-box="0 0 24 24"
            class="w-5 h-5"
          >
            <Eye />
          </Icon>
          <span>Disable your advert blocker</span>
        </h3>
        <p class="my-2">
          It looks like you’re using an advert or content blocker.
          The test may not work correctly while this is enabled.
        </p>
        <p class="my-2">
          We recommend you safelist this website or turn off the blocker.
        </p>
      </div>

      <div
        ref="startButton"
        class="banner_ad text-ad marketing-message"
      >
        &nbsp;
      </div>

      <div
        class="text-center"
      >
        <BaseButton
          v-if="exam && canStartExam(exam.slug)"
          class="mx-auto mt-6 text-lg"
          @click="$emit('begin-test', true)"
        >
          Begin test
          <template slot="iconRight">
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 42 42"
            >
              <Play />
            </Icon>
          </template>
        </BaseButton>
        <div v-else>
          <BaseButton
            class="mx-auto mt-6 text-lg"
            disabled
          >
            Incomplete
            <template slot="iconRight">
              <Icon
                width="16px"
                height="16px"
                view-box="0 0 42 42"
                class="ml-2"
              >
                <Play />
              </Icon>
            </template>
          </BaseButton>
        </div>
      </div>
    </div>
    <div
      v-if="!exam"
      class="p-16"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import Loader from '@components/Loader'
import Icon from '@components/Icons/Icon'
import Play from '@components/Icons/Play'
import Eye from '@components/Icons/Eye'

import { mapGetters } from 'vuex'
import { getLocalStorage, setLocalStorage } from '@utils/localStorage'

export default {
  components: {
    Loader,
    Icon,
    Play,
    Eye
  },

  props: {
    exam: {
      type: Object,
      required: true
    },
    timerDisabled: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      showExtraTimeOption: false,
      canSeeStart: true
    }
  },

  computed: {
    ...mapGetters({
      canStartExam: 'candidates/canStartExam',
      extraTime: 'attempt/extraTime',
      organisationSpecialFeatures: 'candidates/organisationSpecialFeatures'
    }),

    /**
     * @return {Boolean}
     */
    extraTimeAllowed() {
      if (!this.exam) {
        return false
      }

      return this.exam.minutes && !this.organisationSpecialFeatures.includes('doNotAllowExtraTime')
    },

    hasExtraTime: {
      get() {
        return this.extraTime
      },
      set(value) {
        this.setExtraTime(value)
      }
    }
  },

  mounted() {
    if (this.extraTimeAllowed) {
      if (getLocalStorage('useExtraTime')) {
        // Extra time has previously been used, so use it again
        this.showExtraTimeOption = true
        this.$store.commit('attempt/setExtraTime', true)
      }
    }

    this.$nextTick(() => {
      if (!this.$refs.startButton) {
        return
      }
      const styles = window.getComputedStyle(this.$refs.startButton)
      this.canSeeStart = styles.display !== 'none'
    })
  },

  methods: {
    /**
     * Set the extra time status
     *
     * @param {Boolean} checked
     */
    setExtraTime(checked) {
      setLocalStorage('useExtraTime', checked)

      this.$store.commit('attempt/setExtraTime', checked)
    }
  }
}
</script>
