import { pickedApiRequest } from './request'

export default {
  /**
   * Candidate submit exam review
   * { feedback: candidateUuid, body, title, rating, examSuiteSlug, examSlug }
   * @param {Object} review
   * @return {Object}
   */
  createReview (review) {
    return pickedApiRequest.post('assessment/reviews', review)
  },

  /**
   * Candidate submit exam feedback
   * { feedback: candidateUuid, body, title, rating, jobId }
   * @param {Object} feedback
   * @return {Object}
   */
  createFeedback (feedback) {
    return pickedApiRequest.post('assessment/job-reviews', feedback)
  },

  /**
   * Employer submit product feedback
   * @param {Object} feedback
   * @return {Object}
   */
  createClientFeedback (feedback) {
    return pickedApiRequest.post('assessment/client-feedback', feedback)
  }
}
