import { pickedApiRequest } from './request'

export default {
  /**
   * Save a candidate's demographic data anonymously
   *
   * @param {Object} data
   * @return {Object}
   */
  store (data) {
    return pickedApiRequest.post('assessment/demographic-data', data)
      .then(response => {
        return response.data.data
      })
  }
}
