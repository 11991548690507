<template>
  <Modal
    :open="modalOpen"
    @close="modalOpen = false"
  >
    <div class="border-b-2 bg-white">
      <div class="flex justify-between px-6 pb-6">
        <h3 class="font-medium text-lg">
          Assessments Complete!
        </h3>
        <button
          class="text-gray-500 focus:outline-none"
          @click="$emit('close')"
        >
          <Icon
            width="16px"
            height="16px"
            view-box="0 0 14 14"
            class="transform rotate-45"
          >
            <Plus />
          </Icon>
        </button>
      </div>
    </div>

    <div class="p-6 mx-auto text-center">
      <Fireworks></Fireworks>

      <p class="text-lg my-6">
        Thank you for completing your assessments.
      </p>

      <BaseButton :to="{ name: 'candidate-wizard-dashboard' }">
        Go to Dashboard
      </BaseButton>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'
import Fireworks from '@components/Vectors/Fireworks'

export default {
  components: {
    Modal,
    Icon,
    Plus,
    Fireworks
  },

  props: {
    modalOpen: {
      type: Boolean,
      default: false
    }
  }
}
</script>
