<template>
  <div class="flex items-center text-secondary">
    <Icon
      height="30px"
      width="30px"
      view-box="0 0 24 24"
    >
      <Timer/>
    </Icon>
    <div class="w-20 leading-none mb-1">
      <span class="text-2xl text-gray-800 font-medium">{{ formatMinutes }}:{{ formatSeconds }}</span>
    </div>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Timer from '@components/Icons/Timer'

import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    Timer
  },

  props: {
    timeInMinutes: {
      type: Number,
      default: 0
    },
    stop: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      minutes: 0,
      seconds: 0,
      attemptTimeInSeconds: 0,
      interval: null,
      started: false
    }
  },

  computed: {
    ...mapGetters({
      extraTime: 'attempt/extraTime'
    }),

    /**
     * @return {Number}
     */
    formatMinutes() {
      return ('0' + this.minutes).slice(-2)
    },

    /**
     * @return {Number}
     */
    formatSeconds() {
      if (this.seconds < 10) {
        return '0' + parseInt(this.seconds, 10)
      }
      return this.seconds
    },

    totalExamTimeInSeconds() {
      return this.formatMinutes * 60
    }
  },

  watch: {
    stop(value) {
      if (value) {
        this.started = false
        this.$store.dispatch('attempt/setSpeedCompleted', this.attemptTimeInSeconds)
      }
    }
  },

  created() {
    this.minutes = this.timeInMinutes
    if (this.extraTime) {
      // + 25%
      console.log('Adding 25% extra time to', this.minutes)
      this.minutes = parseInt((this.minutes / 100) * 25 + this.minutes)
      console.log('Minutes is now', this.minutes)
    }
    this.started = true
    this.interval = setInterval(this.intervalCallback, 1000)
  },

  methods: {
    intervalCallback() {
      if (!this.started) {
        return false
      }

      if (this.seconds === 0) {
        if (this.minutes === 0) {
          this.timerComplete()
          return null
        }
        this.seconds = 59
        this.minutes--
      } else {
        this.$store.dispatch('attempt/setSpeedCompleted', this.attemptTimeInSeconds++)
        this.seconds--
      }
    },

    timerComplete() {
      this.started = false
      this.$emit('time-is-up')
      this.$store.dispatch('attempt/setSpeedCompleted', this.attemptTimeInSeconds)
    }
  }
}
</script>
