<template>
  <div
    class="py-16 bg-gray-100"
  >
    <div class="max-w-6xl mx-auto bg-white">
      <h2 class="px-4 pt-8 text-3xl font-semibold sm:px-12">
        Section {{ pageNumber + 1 }}
      </h2>
      <div class="overflow-x-auto">
        <div class="inline-block w-full px-4 sm:px-12">
          <table class="w-full table-auto sm:table-fixed">
            <thead>
              <tr>
                <th class="py-4 pr-4 text-lg text-left">
                  <template v-if="questionPreface">
                    {{ questionPreface }}
                  </template>
                </th>
                <th class="w-24 p-4 text-xs font-normal leading-tight text-gray-800">
                  Strongly disagree
                </th>
                <th class="w-24 p-4 text-xs font-normal leading-tight text-gray-800">
                  Disagree
                </th>
                <th class="w-24 p-4 text-xs font-normal leading-tight text-gray-800">
                  Neither
                </th>
                <th class="w-24 p-4 text-xs font-normal leading-tight text-gray-800">
                  Agree
                </th>
                <th class="w-24 p-4 text-xs font-normal leading-tight text-gray-800">
                  Strongly agree
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(question, index) in questions"
                :key="index"
                class="border-b"
              >
                <td class="w-full text-sm">
                  {{ question.body }}
                </td>
                <td
                  v-for="(value, option) in options"
                  :key="option.uuid"
                  class="px-4 py-4 mx-auto text-center"
                >
                  <label class="inline-flex items-center">
                    <input
                      v-model="answers[question.item]"
                      type="radio"
                      class="w-6 h-6 form-radio text-secondary"
                      :name="index"
                      :value="value"
                      @change="setAnswer(question.item, value)"
                    >
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="w-full pt-8 pb-16">
        <div class="flex justify-center mx-auto text-center">
          <BaseButton
            class="mr-4"
            :disabled="pageNumber === 0"
            variant="inverse"
            @click="$emit('prev')"
          >
            Back
          </BaseButton>
          <BaseButton
            v-if="!isLastQuestion"
            :loading="submitInProgress"
            :disabled="!answersOnPageComplete(pageNumber, personalityTraits, examSlug)"
            @click="next()"
          >
            Next
          </BaseButton>
          <AssessmentEndButton
            v-else
            :submit-error="submitError"
            :loading="submitInProgress || endTestEmitted"
            button-type="button"
            button-text="Finish"
            @endTest="endTest"
          />
        </div>
      </div>
    </div>

    <Modal
      :open="modalOpen"
      @close="$emit('close')"
    >
      <div class="bg-white border-b-2">
        <div class="flex items-center px-6 pb-6">
          <Icon
            width="42px"
            height="42px"
            view-box="0 0 80 80"
            class="mr-2"
          >
            <Celebration />
          </Icon>
          <h3 class="text-lg font-medium">
            Example test ended
          </h3>
        </div>
      </div>

      <div class="p-6">
        <BaseButton
          href="/tests/personality/"
        >
          Back to tests
        </BaseButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import options from '@data/personalityTest/personalityTestOptions'
import AssessmentEndButton from '@components/Assessments/AssessmentEndButton'

import Celebration from '@components/Icons/Celebration'
import Modal from '@components/Modal'
import Icon from '@components/Icons/Icon'

import { mapGetters } from 'vuex'

export default {
  components: {
    AssessmentEndButton,
    Celebration,
    Modal,
    Icon
  },

  props: {
    index: {
      type: Number,
      default: 0
    },

    examSlug: {
      type: String,
      default: ''
    },

    questionPreface: {
      type: String,
      default: ''
    },

    isLastQuestion: {
      type: Boolean,
      default: false
    },

    submitError: {
      type: String,
      default: null
    },

    nextText: {
      type: String,
      default: null
    },

    submitInProgress: {
      type: Boolean,
      default: false
    },

    pageNumber: {
      type: Number,
      default: 0
    },

    personalityTraits: {
      type: Array,
      default: () => []
    },

    isExample: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      options,
      answers: {},
      modalOpen: false,
      endTestEmitted: false
    }
  },

  computed: {
    ...mapGetters({
      questionsByPage: 'personality/questionsByPage',
      answersOnPageComplete: 'personality/answersOnPageComplete'
    }),

    /**
     * Returns questions for this page
     *
     * @return {Array}
     */
    questions() {
      return this.questionsByPage(this.pageNumber, this.personalityTraits, this.isExample, this.examSlug)
    }
  },

  methods: {
    setAnswer(question, answer) {
      this.$store.dispatch('personality/setAnswer', { question, answer })
    },

    next() {
      this.$emit('next')
      window.scrollTo(0, 0)
    },

    endTest() {
      if (this.isExample) {
        this.modalOpen = true
        return
      }

      if (this.endTestEmitted) {
        console.error('End test already sent')
      }
      this.endTestEmitted = true

      this.$emit('endTest')
    }
  }
}
</script>
