<template>
  <div>
    <ais-search-box>
      <div slot-scope="{ currentRefinement, refine }">
        <input
          v-if="v"
          ref="input"
          v-model="v.$model"
          type="search"
          class="w-full form-input rounded-lg"
          v-bind="$attrs"
          @input="handleInput(refine, $event.currentTarget.value)"
          @focus="$event.target.select()"
          v-on="$listeners"
        />
        <input
          v-else
          ref="input"
          type="search"
          class="w-full form-input rounded-lg"
          v-bind="$attrs"
          @input="handleInput(refine, $event.currentTarget.value)"
          @focus="$event.target.select()"
          v-on="$listeners"
        />
      </div>
    </ais-search-box>
  </div>
</template>

<script>
export default {
  props: {
    searchClient: {
      type: Object,
      default: null
    },
    clear: {
      type: Boolean,
      default: false
    },
    v: {
      type: Object,
      default: null
    }
  },

  methods: {
    focus() {
      this.$refs.input.focus()
    },

    chooseCurrentRefinement(currentRefinement) {
      return this.clear ? null : currentRefinement
    },

    handleInput(refine, value) {
      refine(value)
      this.$emit('update', value)
    }
  }
}
</script>
