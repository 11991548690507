<template>
  <div class="flex flex-col justify-center">
    <slot :is-showing-answers="isShowingAnswers">
      <a
        v-if="!isShowingAnswers"
        href="#"
        class="underline"
        @click="$emit('open')"
      >
        End test
      </a>
    </slot>

    <a
      v-if="isShowingAnswers"
      class="underline"
      :href="`/tests/${$route.params.assessment}/`"
    >
      Back to tests
    </a>

    <AssessmentExampleCompleteModal
      :modal-open="modalOpen"
      @close="$emit('close')"
      @showAnswers="$emit('showAnswers')"
    />
  </div>
</template>

<script>
import AssessmentExampleCompleteModal from '@components/Assessments/AssessmentExampleCompleteModal'

export default {
  components: {
    AssessmentExampleCompleteModal
  },

  props: {
    modalOpen: {
      type: Boolean,
      default: false
    },
    isShowingAnswers: {
      type: Boolean,
      default: false
    }
  }
}
</script>
