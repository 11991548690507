<template>
  <div
    ref="assessmentContainer"
    class="h-screen bg-white"
    @mouseleave="mouseLeaveAssessmentsWindow"
  >
    <video
      ref="camera"
      :width="300"
      :height="300"
      autoplay
      class="hidden"
    />
    <FetchCandidate>
      <template slot-scope="{ candidate }">
        <template v-if="candidate">
          <div v-if="exam">
            <AssessmentInstructions
              v-if="!assessmentStarted"
              :exam="exam"
              @begin-test="startExam($event)"
            />
            <AssessmentContainer
              v-else
              :organisation-id="organisationId"
              :candidate="candidate"
              :job-uuid="job.uuid"
              :job-wizard-slug="job.wizardSlug"
              :job-exams="jobExamSlugs"
              :anti-cheat-data="antiCheatData"
              :exam="exam"
            />
          </div>
          <div
            v-else
            class="flex-grow my-12"
          >
            <Loader />
          </div>
        </template>
      </template>
    </FetchCandidate>
  </div>
</template>

<script>
import AssessmentContainer from '@components/Assessments/AssessmentContainer'
import Loader from '@components/Loader'
import AssessmentInstructions from '@components/Assessments/AssessmentInstructions'
import FetchCandidate from '@components/CandidateWizard/FetchCandidate'
import candidateWizardApi from '@api/candidateWizard'

import { mapGetters } from 'vuex'
import { antiCheating } from '@utils/mixins/antiCheating'

export default {
  components: {
    AssessmentContainer,
    AssessmentInstructions,
    FetchCandidate,
    Loader
  },

  mixins: [antiCheating],

  data() {
    return {
      assessmentStarted: false,
      job: null,
      exams: []
    }
  },

  computed: {
    ...mapGetters({
      examBySlug: 'exams/examBySlug',
      haveExamsLoaded: 'exams/haveExamsLoaded'
    }),

    /**
     * @return {String}
     */
    examSlug() {
      return this.$route.params.assessment
    },

    /**
     * @return {String}
     */
    jobUuid() {
      return this.$route.params.job
    },

    /**
     * @return {Object}
     */
    exam() {
      if (!this.exams.length) {
        return null
      }
      return this.exams.find(exam => {
        return exam.slug === this.examSlug
      })
    },

    /**
     * @return {String}
     */
    organisationId() {
      return this.exam.organisationId
    },

    /**
     * @return {Array}
     */
    jobExamSlugs() {
      if (!this.job) {
        return
      }
      return this.exams.map(exam => exam.slug)
    }
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.launchReloadWarning)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.launchReloadWarning)
    })
  },

  created() {
    if (!this.haveExamsLoaded) {
      this.$store.dispatch('exams/getExams')
    }
    this.getJob()
  },

  methods: {
    /**
     * @param {Boolean} toggle
     */
    startExam(toggle) {
      this.assessmentStarted = toggle
      if (window.width > 1024) {
        this.requestFullScreenAssessment()
      }
    },

    /**
     * Launch reload page generic browser alert
     */
    launchReloadWarning(event) {
      if (!this.assessmentStarted) {
        return
      }
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ''
    },

    getJob() {
      return candidateWizardApi.job(this.jobUuid)
        .then(response => {
          this.job = response.data
          this.exams = [...response.data.exams, ...response.data.customExams]
          if (this.job.customExams.length) {
            this.$store.dispatch('exams/storeCustomExams', this.job.customExams)
          }
          this.$store.commit('candidates/storeOrganisationSpecialFeatures', this.job.organisationSpecialFeatures)
        })
        .catch(error => {
          throw error
        })
    }
  }
}
</script>
