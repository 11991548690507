<template>
  <div>
    <Modal
      :open="modalOpen"
      size="max-w-4xl"
      @close="modalOpen = false"
    >
      <div class="p-6 pt-0 relative">
        <button
          class="text-gray-500 focus:outline-none absolute top-0 right-0 mr-4 mt-1"
          @click="closeModal"
        >
          <Icon
            width="16px"
            height="16px"
            view-box="0 0 14 14"
            class="transform rotate-45"
          >
            <Plus />
          </Icon>
        </button>
        <form
          class="text-left"
          @submit.prevent
        >
          <div class="flex items-center">
            <h2 class="text-left text-xl font-medium m-0">
              Thank you. How would you rate your first experience of Picked?
            </h2>
          </div>
          <div class="my-4">
            <h3 class="text-xs">
              Overall rating
            </h3>
            <StarRating
              v-model="formResponses.rating"
              :show-rating="false"
              :star-size="20"
            />
          </div>
          <div class="grid md:grid-cols-2 gap-4">
            <div class="mb-4">
              <label
                for="email"
                class="text-xs mb-1 block"
              >
                Add a headline
              </label>
              <input
                v-model="$v.formResponses.title.$model"
                type="text"
                placeholder="What’s most important to know?"
                class="w-full form-input rounded-lg"
              >
              <div class="relative">
                <span
                  v-if="hasErrors && !$v.formResponses.title.required"
                  class="text-red-500 text-xs absolute left-0 top-1"
                >
                  This field is required
                </span>
              </div>
            </div>
            <div class="mb-4">
              <div class="flex justify-between text-xs">
                <label
                  for="email"
                  class="text-xs mb-1 block"
                >
                  Add a written review
                </label>
                <span
                  class="text-nevada"
                  v-html="characterCounter"
                >
                </span>
              </div>
              <textarea
                v-model="$v.formResponses.body.$model"
                maxlength="280"
                placeholder="What did you like or dislike about the experience?"
                class="w-full form-input resize-none rounded-lg"
                style="height: 80px;"
              />
              <div class="relative">
                <span
                  v-if="hasErrors && !$v.formResponses.body.required"
                  class="text-red-500 text-xs absolute left-0 top-1"
                >
                  This field is required
                </span>
              </div>
            </div>
          </div>

          <div class="border text-xs bg-gray-50 text-gray-600 p-2 mb-4 rounded-lg">
            Below you will find a few demographic questions. This information is fully optional and is collected anonymously. This data assists us in making sure our tests are appropriate and fair for a diverse population.
          </div>

          <div class="grid md:grid-cols-2 gap-4">
            <div>
              <label
                for="grade"
                class="text-xs mb-1 block"
              >
                Education level
              </label>
              <div class="mt-1">
                <select
                  id="grade"
                  v-model="$v.formResponses.grade.$model"
                  name="grade"
                  class="form-select rounded-lg w-full text-sm"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    Please select
                  </option>
                  <option
                    v-for="(classification, classificationIdx) in classifications"
                    :key="classificationIdx"
                    :value="classification"
                  >
                    {{ classification }}
                  </option>
                </select>
              </div>
            </div>

            <div>
              <label
                for="course"
                class="text-xs block"
              >
                Course
              </label>
              <SearchSelectMenu
                index-name="courses"
                :v="$v.formResponses.course"
                :search-client="searchClient"
                @selected="formResponses.course = $event"
              />
            </div>

            <div>
              <label
                for="role"
                class="text-xs mb-1 block"
              >
                Relevant role experience
              </label>
              <SearchSelectMenu
                index-name="role_positions_en"
                :v="$v.formResponses.role.name"
                :search-client="searchClient"
                @selected="formResponses.role.name = $event"
              />
            </div>

            <div>
              <label
                for="roleExperienceYears"
                class="text-xs mb-1 block"
              >
                Years of experience
              </label>
              <input
                id="roleExperienceYears"
                v-model.number="$v.formResponses.role.years.$model"
                type="number"
                min="0"
                max="80"
                step="1"
                class="w-full form-input rounded-lg"
              >
            </div>

            <div>
              <label
                for="age"
                class="text-xs mb-1 block"
              >
                Age
              </label>
              <input
                id="age"
                v-model="$v.formResponses.age.$model"
                type="number"
                min="0"
                max="100"
                step="1"
                class="w-full form-input rounded-lg"
              >
            </div>

            <div>
              <label
                for="language"
                class="text-xs mb-1 block"
              >
                Language proficiency
              </label>
              <div class="mt-1">
                <select
                  id="language"
                  v-model="$v.formResponses.language.$model"
                  name="language"
                  class="form-select rounded-lg w-full text-sm"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    Please select
                  </option>
                  <option
                    v-for="(proficiency, proficiencyIdx) in antiDiscrimination.languageProficiency"
                    :key="proficiencyIdx"
                    :value="proficiency.value"
                  >
                    {{ proficiency.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="grid md:grid-cols-3 gap-4 mt-4">
            <div>
              <label
                for="gender"
                class="text-xs mb-1 block"
              >
                Gender
              </label>
              <div class="mt-1">
                <select
                  id="language"
                  v-model="$v.formResponses.gender.$model"
                  name="language"
                  class="form-select rounded-lg w-full text-sm"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    Please select
                  </option>
                  <option
                    v-for="(gender, genderIdx) in antiDiscrimination.gender"
                    :key="genderIdx"
                    :value="gender.value"
                  >
                    {{ gender.text }}
                  </option>
                </select>
              </div>
            </div>

            <div>
              <label
                for="ethnicity"
                class="text-xs mb-1 block"
              >
                Ethnicity
              </label>
              <div class="mt-1">
                <select
                  id="language"
                  v-model="$v.formResponses.ethnicity.$model"
                  name="language"
                  class="form-select rounded-lg w-full text-sm"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    Please select
                  </option>
                  <option
                    v-for="(ethnicity, ethnicityIdx) in antiDiscrimination.ethnicity"
                    :key="ethnicityIdx"
                    :value="ethnicity.value"
                  >
                    {{ ethnicity.text }}
                  </option>
                </select>
              </div>
            </div>

            <div>
              <label
                for="countries"
                class="text-xs mb-1 block"
              >
                Country
              </label>
              <div class="mt-1">
                <select
                  id="countries"
                  v-model="$v.formResponses.country.$model"
                  name="countries"
                  class="form-select rounded-lg w-full text-sm"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    Please select
                  </option>
                  <option
                    v-for="(country, countryIdx) in countries"
                    :key="countryIdx"
                    :value="country.value"
                  >
                    {{ country.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="grid md:grid-cols-2 gap-4 mt-4">
            <div>
              <label
                for="disability"
                class="text-xs mb-1 block"
              >
                Disability
              </label>
              <div class="mt-1">
                <select
                  id="disability"
                  v-model="$v.formResponses.disability.$model"
                  name="disability"
                  class="form-select rounded-lg w-full text-sm"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    Please select
                  </option>
                  <option
                    v-for="(disability, disabilityIdx) in antiDiscrimination.disability"
                    :key="disabilityIdx"
                    :value="disability.value"
                  >
                    {{ disability.text }}
                  </option>
                </select>
              </div>
            </div>
            <div>
              <label
                for="disabilityImpact"
                :class="['text-xs mb-1 block', !hasDisability ? 'text-gray-500' : 'text-gray-800']"
              >
                Disability impact
              </label>
              <div class="mt-1">
                <select
                  id="disabilityImpact"
                  v-model="$v.formResponses.disabilityImpact.$model"
                  name="disabilityImpact"
                  :disabled="!hasDisability"
                  class="form-select rounded-lg w-full text-sm"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    Please select
                  </option>
                  <option
                    v-for="(disabilityImpact, disabilityImpactIdx) in antiDiscrimination.disabilityImpact"
                    :key="disabilityImpactIdx"
                    :value="disabilityImpact.value"
                  >
                    {{ disabilityImpact.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <div
          style="min-height: 50px;"
          class="mt-6 text-right"
        >
          <BaseButton
            v-if="!feedbackSubmitted"
            :disabled="feedbackSubmitted"
            :loading="processing"
            @click.prevent="submitFeedback"
          >
            Submit
          </BaseButton>
          <p
            v-else
            class="inline-block text-2xl font-medium rounded-sm p-2"
          >
            Thank you 👍
          </p>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import StarRating from 'vue-star-rating'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'
import SearchSelectMenu from '@components/Algolia/SearchSelectMenu'
import { searchClient } from '@utils/algoliaClient'

import { getLocalStorage } from '@utils/localStorage'
import countries from '@groupResources/lists/countries'
import antiDiscrimination from '@groupResources/lists/antiDiscrimination'
import classifications from '@groupResources/lists/educationClassifications'

import feedbackApi from '@api/feedback'
import demographicApi from '@api/demographic'

export default {
  components: {
    Modal,
    StarRating,
    Icon,
    Plus,
    SearchSelectMenu
  },

  mixins: [validationMixin],

  props: {
    jobId: {
      type: String,
      default: null
    },
    modalOpen: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      countries,
      antiDiscrimination,
      classifications,

      searchClient,
      processing: false,
      feedbackSubmitted: false,
      hasErrors: false,
      errors: [],
      feedbackCharacterLimit: 280,

      formResponses: {
        title: '',
        body: '',
        rating: 5,

        country: null,
        grade: null,
        course: null,
        role: {
          name: null,
          years: null
        },
        age: null,
        language: null,
        gender: null,
        ethnicity: null,
        disability: null,
        disabilityImpact: null
      }

    }
  },

  validations: {
    formResponses: {
      title: {
        required
      },
      body: {
        required
      },

      country: {},
      grade: {},
      course: {},
      role: {
        name: {},
        years: {}
      },
      age: {},
      language: {},
      gender: {},
      ethnicity: {},
      disability: {},
      disabilityImpact: {}
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    isClean() {
      return !this.$v.formResponses.$anyDirty
    },

    characterCounter() {
      return `<span class="w-16">${(this.feedbackCharacterLimit - this.formResponses.body.length)}</span> / <span class="w-16">${this.feedbackCharacterLimit}</span>`
    },

    hasDisability() {
      return this.formResponses.disability === 'true'
    }
  },

  methods: {
    submitFeedback() {
      this.processing = true

      // Validate fields
      this.$v.formResponses.$touch()
      this.hasErrors = this.$v.formResponses.$anyError
      if (this.hasErrors || this.isClean) {
        this.processing = false
        return
      }

      const feedback = {
        userId: getLocalStorage('candidateWizard.uuid'),
        title: this.formResponses.title,
        body: this.formResponses.body,
        rating: this.formResponses.rating,
        jobId: this.jobId
      }

      // Destructuring demographic fields
      const {
        country,
        grade,
        course,
        role,
        age,
        language,
        gender,
        ethnicity,
        disability,
        disabilityImpact
      } = this.formResponses

      return feedbackApi.createFeedback(feedback)
        .then((response) => {
          this.processing = false
          this.feedbackSubmitted = true

          const submittedFeedback = {
            event: 'event',
            eventCategory: 'Feedback',
            eventAction: 'Feedback for Picked Tests',
            eventLabel: 'Job Tests Review'
          }
          this.$gtm.trackEvent(submittedFeedback)

          demographicApi.store({
            country,
            grade,
            course,
            role,
            age,
            language,
            gender,
            ethnicity,
            disability,
            disabilityImpact
          }).then((response) => {
            setTimeout(() => {
              this.$emit('close')
            }, 1500)
          })
        })
        .catch(error => {
          this.processing = false
          if (error.response && error.response.data.errors) {
            console.error(error)
            this.errors = error.response.data.errors
            return
          }
          throw error
        })
    },

    closeModal() {
      this.$emit('close')
    }
  }
}
</script>
