<template>
  <div>
    <a
      v-if="buttonType === 'inline'"
      href="#"
      class="underline"
      @click="openModal()"
    >
      {{ buttonText }}
    </a>

    <BaseButton
      v-if="buttonType === 'button'"
      :disabled="disabled"
      :loading="loading || modalInTransition"
      @click="openModal()"
    >
      {{ buttonText }}
    </BaseButton>

    <Modal
      :open="modalOpen"
      @close="closeModal()"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Are you sure you want to end this test?
          </h3>
        </div>
      </div>

      <p
        v-if="submitError"
        class="px-6 pt-6"
      >
        {{ submitError }}
      </p>

      <div class="p-6 flex justify-between items-center">
        <BaseButton
          class="min-w-button"
          variant="ghost"
          :disabled="submitInProgress"
          @click="cancelTest()"
        >
          Cancel
        </BaseButton>

        <BaseButton
          class="min-w-button"
          variant="green"
          :disabled="!!submitError"
          :loading="submitInProgress"
          @click="endTest()"
        >
          Submit
        </BaseButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'

export default {
  components: {
    Modal
  },

  props: {
    submitError: {
      type: String
    },
    submitInProgress: {
      type: Boolean
    },
    buttonText: {
      type: String,
      default: 'End test'
    },
    buttonType: {
      type: String,
      default: 'inline'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      modalOpen: false,
      modalInTransition: false
    }
  },

  watch: {
    submitInProgress(val) {
      if (val) {
        this.closeModal()
      }
    }
  },

  methods: {
    /**
     * Open the modal
     */
    openModal() {
      if (this.modalInTransition) {
        console.error('Modal is already in transition')
        return
      }

      // Due to a transition delay (or similar), the modal does not open
      // straight away, so we set modalInTransition to true first to disable
      // duplicate clicks and give the user feedback something is happening
      this.modalInTransition = true
      this.modalOpen = true
    },

    /**
     * Close the modal
     */
    closeModal() {
      this.modalOpen = false
      this.modalInTransition = false
    },

    endTest() {
      if (this.submitInProgress) {
        return
      }
      this.$emit('endTest')
    },

    cancelTest() {
      this.closeModal()
      if (this.submitError) {
        this.$emit('examCancelledAfterError')
      }
    }
  }
}
</script>
